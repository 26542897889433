<template>
  <div class="row" style="display:flex;  margin: auto;padding-bottom:2%;padding-top:2%;margin-bottom:5%;">
   
    <div class="col-sm-12 col-12">

      <div class="fixed-grid" style="margin-bottom:5%;margin-top:5%;">
        <div class="row">
          <div class="col-sm-4 col-12" style="margin-bottom:5px;">
            <p style="color:#585858;font-size:22px;font-weight:bold">BUSCAR POR LOCALIDAD</p>
            <b-field style="font-size:22px;">
                <b-autocomplete
                    rounded
                    size="is-large"
                    v-model="searchTextLocalidad"
                    :data="filteredItemsLocalidad"
                      open-on-focus="true"
                    placeholder="e.g. Madrid"
                    icon="magnify"
                     field="poblacion"
                    clearable
                    @select="onSelect2">
                    <template #empty>No se encuentran resultados</template>
                </b-autocomplete>
            </b-field>         
          </div>
          <div class="col-sm-4 col-12" style="margin-bottom:5px;">
            <p style="color:#585858;font-size:22px;font-weight:bold">BUSCAR POR SECTOR</p>
            <b-field style="font-size:22px;">
                <b-autocomplete
                    rounded
                    size="is-large"
                    v-model="searchText"
                    :data="filteredItems"
                      open-on-focus="true"
                    placeholder="e.g. Pintores"
                    icon="magnify"
                     field="nombreServicio"
                    clearable
                    @select="onSelect">
                    <template #empty>No se encuentran resultados</template>
                </b-autocomplete>
            </b-field>         
          </div>
          <div class="col-sm-4 col-12" style="margin-bottom:5px;margin-top:1%;">  
                <b-button rounded type="is-danger" size="is-large"  @click="emitClick">Encontrar Profesionales</b-button>
            
          </div>
        </div>

        <div class="row">

        </div>   
    </div>
    </div>
    
  </div>
  <div class=row style="margin-bottom:5%;">
    <div class="col-12">
        <span style="color:#585858;font-size:22px;"><strong>
            En ETHI Servicios, te conectamos con los profesionales más cualificados para que lleven a cabo tus reformas, instalaciones o proyectos de construcción y diseño. Ya sea que necesites un electricista, fontanero, carpintero, arquitecto o cualquier otro especialista, aquí encontrarás la solución perfecta.
        </strong>
        </span>
    </div>
</div>
  <div class="row" style="margin-bottom:5%;">
    <div class="col-12">
      <h1 style="font-size:28px;"><strong style="color:black;">ENCUENTRA LOS MEJORES PROFESIONALES SEGUN SU ESPECIALIDAD</strong></h1>
    </div>
  </div>
  <div class="row" style="margin: 0 auto;">
    <div class="col-md-2 col-6" style="margin-bottom:5%;" v-for="item in servicios" :key="item.id">
        <strong>
          <div  @click="getProfesionales(item.nombreServicio)">
            <img :src="item.imagen" style="width:64px;height:64px;"/>
            <br/><span style="font-size:22px;color:#585858;">{{item.nombreServicio}} </span></div>
        </strong>      
    </div>
  </div>
</template>

<script>
 import { ref, computed,onMounted } from 'vue';
import useProfesionales from '@/composables/Profesionales.js'
import useMaestras from '@/composables/Maestras.js'
import { useRouter} from 'vue-router'
import { useMiStore } from '@/store/store';

export default {
  name: 'HelloWorld',
  setup() {
        const {  getServicios,servicios,getLocalidadesAll,localidades }=useMaestras()
        const { getProfesionalPaged } =useProfesionales()
        const router = useRouter()
        const selectedItem = ref(null);
        const searchText = ref('');
        const searchTextLocalidad=ref('')
        const location = ref(null);
        const error = ref(null);
        const store = useMiStore();
  
       

    
        onMounted(async () => {
           await  getServicios();
             getLocalidadesAll();
        })


    async function getProfesionales(id)
    {
      console.log(id)
      console.log(store.codigoPostal)
      router.push({name: 'DirectorioPid',params:{id:id}})
    }

    const filteredItems = computed(() => {
        console.log(searchText.value)
      if (!searchText.value) {
        return servicios.value;
      }
      return servicios.value.filter(item =>
        item.nombreServicio.toLowerCase().includes(searchText.value.toLowerCase())
      );
    });


    const filteredItemsLocalidad = computed(() => {
        console.log(searchTextLocalidad.value)
      if (!searchTextLocalidad.value) {
        return localidades.value;
      }
      return localidades.value.filter(item =>
        item.poblacion.toLowerCase().includes(searchTextLocalidad.value.toLowerCase())
      );
    });

    const onSelect = (item) => {
      console.log('Selected item:', item);
    };

    const onSelect2 = (item) => {
      console.log('Selected item:', item);
    };

    const emitClick = () => {
      console.log("Código Postal")
      console.log(searchTextLocalidad.value)
      router.push({name: 'DirectorioP',params:{id:searchText.value,location:searchTextLocalidad.value}})
    };

   
    const getLocation = () => {
      console.log(navigator.geolocation)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          
          (position) => {
            location.value = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            
            };
            const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${location.value.latitude}&lon=${location.value.longitude}`;
            fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data && data.address) {
                    console.log(data);
                    // Aquí puedes obtener diferentes partes de la dirección, como ciudad, país, etc.
                    const localidad = data.address.suburb  || data.address.village || data.address.city || "Localidad no disponible";
                    const provincia =  data.address.town || data.address.state || "Provincia no disponible";
                    const codigoPostal = data.address.postcode || "El código postal no existe";
                    const pais = data.address.country || "El país no existe";
                    const calle = data.address.road || "La vía no existe";
                    console.log("Código Postal:", codigoPostal);
                    console.log("País:", pais);
                    console.log("Localidad "+localidad)
                    console.log("Calle "+calle)
                    console.log("Provincia "+provincia)
                    searchTextLocalidad.value=localidad
                    store.setCodigoPostal(data)
                  
                } else {
                    console.log("No se encontraron resultados.");
                }
      })
      .catch(error => {
          console.error("Error al hacer la petición a la API de Nominatim:", error);
      });
          },
          (err) => {
            error.value = "Error al obtener la ubicación: " + err.message;
          }
        );
      } else {
        error.value = "Geolocalización no es soportada por este navegador.";
      }
    };

    onMounted(() => {
      getLocation();
    });

    return {
        servicios,getServicios,getProfesionales,getProfesionalPaged,searchTextLocalidad,localidades,getLocalidadesAll,
        emitClick,selectedItem,searchText,filteredItems,onSelect,location,error,filteredItemsLocalidad,onSelect2
      
    };
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
