import axios from 'axios'

//LOCAL - DESARROLLO
/*const ethiApi=axios.create({
    baseURL:"https://localhost:7112/api/"})*/

//PRE PRODUCCION
/*const ethiApi=axios.create({
        baseURL:"https://dev-ethi-services.azurewebsites.net/api/"})*/

 //PRODUCCION   
const ethiApi=axios.create({
        baseURL:"https://www.ethiservicios.es/api-ethiServicios/api/"})
    
ethiApi.defaults.headers.common["Access-Control-Allow-Origin"]="*"
export default ethiApi  